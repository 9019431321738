import axios from 'axios'
import { getToken } from '../../helpers/authentication'
// Add a request interceptor
axios.defaults.headers.common['Authorization'] = getToken();



export const allAppUser = (data) => axios.post('/api/web-users', data)

export const getAppUser = (data) => axios.post('/api/web-user', data)


export const deleteAppUserServices = (data) => axios.post('/api/user/delete-user', data)
export const activateAppUserServices = (data) => axios.post('/api/user/update-status', data)


