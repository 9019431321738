import React, { useState, useEffect } from 'react'
import { getUserInfo, getUserId } from './../../../helpers/authentication'
import Sidebar from '../../../components/admin/sidebar'



import { allAppUser, deleteAppUserServices, activateAppUserServices } from '../../../services/appUser'

import { makeStyles } from '@material-ui/core/styles';
import Switch from "react-switch";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import loaderImage from "../../../assets/loader1.svg";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReportIcon from '@material-ui/icons/Report';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import FilterListIcon from '@material-ui/icons/FilterList';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import CommuteIcon from '@material-ui/icons/Commute';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { CSVLink } from "react-csv";
import GetAppIcon from '@material-ui/icons/GetApp';

import { FormatDateSearchFilter, FormatDateSearchFilterString, FormatDate } from '../../../helpers/authentication'
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  grid: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    cursor: 'pointer',
    borderRadius: 10
  },
  button: {
    width: '100%',
    height: 40
  },
  buttonExport: {
    backgroundColor: '#218C72',
    marginRight: 22,
    '&:hover': {

      backgroundColor: '#218000',
    }
  },
  gridContainer: {
    padding: 15,
    backgroundColor: '#fff',
    margin: 10,
    borderRadius: 10
  },
  gridHeading: {
    // padding: '5px 15px',
    backgroundColor: '#fff',
    margin: 5,
    borderRadius: 10
  },
  button: {
    width: '100%',
    height: 40
  },
  buttonExport: {
    backgroundColor: '#218C72',
    marginRight: 22,
    '&:hover': {

      backgroundColor: '#218000',
    }
  },
  buttonPrint: {
    backgroundColor: '#D02657',
    '&:hover': {

      backgroundColor: '#D02000',
    }
  },

});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

function AppUser(props) {

  const classes = useStyles();

  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const [csvEmployee, setCsvEmployee] = useState([]);
  const [search, setSearch] = useState('')

  const [startDate, setStartDate] = useState('')

  const [endDate, setEndDate] = useState('')
  const [user, setUser] = useState([])

  useEffect(() => {
    getData(true)
  }, [])

  const getData = async (refresh = false) => {


    setLoading(true)
    let result = await allAppUser({ role: (getUserInfo()?.role || 'none'), search, startDate, endDate });

    setList(result.data.result)
    if (result.data.result && result.data.result.length > 0) {
      let csvEmployeeLocal = []

      result.data.result.forEach(function (item) {
        csvEmployeeLocal.push({


          "Employee Name": item.name,
          "Employee Phone": item.username,
          "Department": item.role,
          "Status": item.status ? "Active" : "Pending",
          "Date": FormatDate(item.date)
          // "Customer Address": item.customerAddress,
          // "Project Size": item.projectSize,
          // "Description": item.description,
          // "Employee Name": result.data.user.name,
          // "Employee Phone": result.data.user.username,

        })
      })
      // console.log(csvEodLocal)

      setCsvEmployee(csvEmployeeLocal)
    }
    setLoading(false)

  }

  const activateUser = (_id, status, key) => {
    activateAppUserServices({
      _id, status
    })
      .then(res => {
        if (res.data.status) {
          let listLocal = [...list]
          listLocal[key].status = status
          setList(listLocal)
        }
      })
      .catch(err => {
        console.error(err)
      })

  }
  const deleteAppUser = (e, _id) => {
    e.preventDefault();
    // validation


    let sure = window.confirm("Are you sure to want to delete?")

    if (sure) {

      const user = {
        _id

      }
      deleteAppUserServices(user)
        .then(res => {
          if (res.data.status === 'success') {

            let listLocal = list.filter(list => list._id !== _id)

            setList(listLocal)
          }
        })
        .catch(err => {
          console.error(err)
        })

    }
  }

  if (loading) {
    return <div className="loader text-center mt-5">
      Loading...<br />     <img src={loaderImage} height="50" width="50" />
    </div>
  }

  return (
    <>
      <Grid container spacing={3}>

        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid container className={classes.gridHeading}><h4>Filter Result</h4></Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>

              <TextField id="filled-basic" label="Search..." style={{ width: '100%' }} value={search}
                onChange={(e) => { setSearch(e.target.value) }} />

            </Grid>

            <Grid item xs={12} md={2}>

              <TextField
                id="date"
                label="From"
                type="date"
                style={{ width: '100%' }}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                value={startDate}
                onChange={(e) => { setStartDate(e.target.value) }}
              />
            </Grid>
            <Grid item xs={12} md={2}>

              <TextField
                id="date"
                label="to"
                type="date"
                style={{ width: '100%' }}
                value={endDate}
                onChange={(e) => { setEndDate(e.target.value) }}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} md={2}>

              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<Icon></Icon>}
                InputLabelProps={{
                  shrink: true,
                }}
                onClick={() => { getData(true) }}

              ><FilterListIcon style={{ marginRight: 5 }} className={classes.iconGroupCustomer} />Filter</Button>

            </Grid>
            <Grid item xs={12} md={2}>

              <Button onClick={() => { window.print() }}
                variant="contained"
                color="primary"
                className={[classes.button, classes.buttonPrint]}
                endIcon={<Icon></Icon>}
              ><PictureAsPdfIcon style={{ marginRight: 5, }} className={classes.iconGroupCustomer} />Print/Pdf</Button>

            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid container className={classes.gridHeading}>
            <Grid item xs={12} md={10}><h4>Employee List</h4></Grid>
            <Grid item xs={12} md={2}>

              <CSVLink data={csvEmployee} filename={"Employee Report from " + FormatDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)) + " to " + FormatDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)) + ".csv"}><Button
                variant="contained"
                color="primary"
                className={[classes.button, classes.buttonExport]}
                endIcon={<Icon></Icon>}
              ><GetAppIcon style={{ marginRight: 2, }} className={classes.iconGroupCustomer} />Export</Button></CSVLink>

            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Name</strong></TableCell>
                      <TableCell align="left"><strong>Phone</strong></TableCell>
                      <TableCell align="left"><strong>Department</strong></TableCell>
                      <TableCell align="left"><strong>Status</strong></TableCell>
                      <TableCell align="left"><strong>Date</strong></TableCell>
                      <TableCell align="left"><strong>Action</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.length > 0 ? list.map((user, key) => {
                      return <TableRow key={key}>
                        <TableCell align="left"><a href="/user-detail" onClick={(e) => { e.preventDefault(); props.history.push('/user-detail/', { id: user._id }) }}>{String(user.name).replace("_", " ").toUpperCase()} </a></TableCell>
                        <TableCell align="left"><a href="/user-detail" onClick={(e) => { e.preventDefault(); props.history.push('/user-detail/', { id: user._id }) }}>{String(user.username).replace("_", " ").toUpperCase()}</a></TableCell>
                        <TableCell align="left">{String(user.role).replace("_", " ").toUpperCase()}</TableCell>
                        <TableCell align="left"><Switch onChange={() => { activateUser(user._id, !user.status, key) }} checked={user.status} /></TableCell>
                        <TableCell align="left">{FormatDate(user?.date)}</TableCell>
                        <TableCell align="left"><button type="button" class="btn btn-outline-danger" onClick={(e) => { deleteAppUser(e, user._id) }}>Delete</button></TableCell>
                      </TableRow>
                    })
                      :
                      <TableRow>
                        <TableCell align="center" colSpan={6}>
                          Record Not Found!
                  </TableCell>
                      </TableRow>
                    }

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

          </Grid>
        </Grid>

      </Grid>
    </>
  )

}
export default AppUser;
