import React from 'react';
import Routes from './routes'
import './assets/app.scss'

function App() {
  return (
    <Routes />
  );
}

export default App;
