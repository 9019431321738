import axios from 'axios'
import { getToken } from '../../helpers/authentication'
// Add a request interceptor
axios.defaults.headers.common['Authorization'] = getToken();



export const allSales = (data) => axios.post('/api/sales/web-view-all', data)

export const saleDetailsService = (data) => axios.post('/api/sales/get', data)

