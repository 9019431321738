import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import GroupIcon from '@material-ui/icons/Group';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Sidebar from '../../components/admin/sidebar'
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ReportIcon from '@material-ui/icons/Report';
import CommuteIcon from '@material-ui/icons/Commute';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ForumIcon from '@material-ui/icons/Forum';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { logOutUser } from '../../helpers/authentication'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f1f1f1',
    minHeight: '100vh'

  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  iconGroup: {
    fontSize: 50,
    color: '#006200',

  },
  iconGroupComplaint: {
    fontSize: 50,
    color: '#ff0000',

  },
  iconGroupSales: {
    fontSize: 50,
    color: '#90ee90',

  },
  iconGroupEod: {
    fontSize: 50,
    color: '#ff4dff',

  },
  iconGroupCustomer: {
    fontSize: 50,
    color: '#005ab3',

  },
  iconGroupVehicle: {
    fontSize: 50,
    color: '#ff8000',

  }, iconGroupQuotation: {
    fontSize: 50,
    color: '#ffa500',

  },

  heading: {
    color: '#222',
    fontWeight: '600',
    fontSize: 15


  },
  cardContent: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
    cursor: 'pointer',
    borderRadius: 10
  },
  iconGroupLogout: {
    color: '#ff0000'
  }
}));

export default function DashBoard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = (e) => {

    logOutUser()

    props.history.push('/')

  }

  const navigation = (e, route) => {
    props.history.push(route)
  }

  return (

    <Grid container spacing={5}>
      <Grid item xs={12} md={4}>
        <Card onClick={(e) => { navigation(e, '/employees/') }}>
          <CardContent className={classes.cardContent}>
            <GroupIcon className={classes.iconGroup} />
            <Typography variant="h5" component="h5" className={classes.heading}>
              Employees
        </Typography>

          </CardContent>

        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card onClick={(e) => { navigation(e, '/customers/') }}>
          <CardContent className={classes.cardContent}>
            <PermIdentityIcon className={classes.iconGroupCustomer} />
            <Typography variant="h5" component="h5" className={classes.heading}>
              Customers
        </Typography>
          </CardContent>

        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card onClick={(e) => { navigation(e, '/sales/') }}>
          <CardContent className={classes.cardContent}>

            <MonetizationOnIcon className={classes.iconGroupSales} />

            <Typography variant="h5" component="h5" className={classes.heading}>
              Sales
        </Typography>

          </CardContent>

        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card onClick={(e) => { navigation(e, '/eod/') }}>
          <CardContent className={classes.cardContent}>

            <ReportIcon className={classes.iconGroupEod} />

            <Typography variant="h5" component="h5" className={classes.heading}>
              Eod
        </Typography>

          </CardContent>

        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card onClick={(e) => { navigation(e, '/vehicle/') }}>
          <CardContent className={classes.cardContent}>

            <CommuteIcon className={classes.iconGroupVehicle} />

            <Typography variant="h5" component="h5" className={classes.heading}>
              Vehicles
        </Typography>

          </CardContent>

        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card onClick={(e) => { navigation(e, '/quotation/') }}>
          <CardContent className={classes.cardContent}>

            <LocalOfferIcon className={classes.iconGroupQuotation} />

            <Typography variant="h5" component="h5" className={classes.heading}>
              Quotations
        </Typography>

          </CardContent>

        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card onClick={(e) => { navigation(e, '/complaint/') }}>
          <CardContent className={classes.cardContent}>

            <ForumIcon className={classes.iconGroupComplaint} />

            <Typography variant="h5" component="h5" className={classes.heading}>
              Complaints
        </Typography>

          </CardContent>

        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card button onClick={() => { logout() }}>
          <CardContent className={classes.cardContent}>

            <ExitToAppIcon className={classes.iconGroupComplaint} />

            <Typography variant="h5" component="h5" className={classes.heading}>
              Logout
        </Typography>

          </CardContent>

        </Card>
      </Grid>
    </Grid>

  );
}
