import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { getAppUser } from '../../../services/appUser'
import { getUserInfo, getUserId } from './../../../helpers/authentication'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import loaderImage from "../../../assets/loader1.svg";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ReportIcon from '@material-ui/icons/Report';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import FilterListIcon from '@material-ui/icons/FilterList';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import CommuteIcon from '@material-ui/icons/Commute';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { CSVLink } from "react-csv";
import Switch from "react-switch";
import { FormatDateSearchFilter, FormatDateSearchFilterString, FormatDate } from '../../../helpers/authentication'
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    grid: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#fff',
        cursor: 'pointer',
        borderRadius: 10
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
    },
    gridContainer: {
        padding: 15,
        backgroundColor: '#fff',
        margin: 10,
        borderRadius: 10
    }, gridHeading: {
        padding: '5px 15px',
        backgroundColor: '#fff',
        margin: 5,
        borderRadius: 10
    },
    gridContainerBottom: {

        flexDirection: 'column',
        display: 'flex',

        backgroundColor: '#fff'


    },
    divTextContainerBottom: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 35
    },
    textPerformance: {
        color: '#009330', fontWeight: 'bold', fontSize: 25, textAlign: 'flex-start', paddingBottom: 20, paddingLeft: 10, textAlign: 'flex-start'
    },
    textMonth: {
        color: '#009330', fontWeight: 'bold', fontSize: 25, paddingLeft: 15, textAlign: 'flex-end'
    },
    button: {
        width: '100%',
        height: 40
    },
    buttonExport: {
        backgroundColor: '#218C72',
        marginRight: 22,
        '&:hover': {

            backgroundColor: '#218000',
        }
    },
    buttonPrint: {
        backgroundColor: '#D02657',
        '&:hover': {

            backgroundColor: '#D02000',
        }
    },
    textField: {
        width: '100%'
    }

}));

export default function UserDetails(props) {
    const classes = useStyles();
    const [userDetail, setUserDetail] = useState({});
    const [sales, setSales] = useState([]);
    const [csvSales, setCsvSales] = useState([]);
    const [csvEod, setCsvEod] = useState([]);
    const [csvVehicle, setCsvVehicle] = useState([])
    const [csvQuotation, setCsvQuotation] = useState([])
    const [csvComplaint, setCsvComplaint] = useState([])

    const [survey, setSurvey] = useState([])
    const [vehicles, setVehicle] = useState([])
    const [eods, setEod] = useState({})
    const [quotations, setQuotation] = useState({})
    const [complaints, setComplaint] = useState({})

    const [loading, setLoading] = useState(true);

    const [startDate, setStartDate] = useState(FormatDateSearchFilter(new Date(new Date().getFullYear(), new Date().getMonth(), 1)))

    const [endDate, setEndDate] = useState(FormatDateSearchFilter(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)))

    useEffect(() => {
        getData(true)
    }, [])

    const getData = async (refresh = false, startDate, endDate) => {
        const _id = props?.location?.state?.id || false

        if (_id) {
            setLoading(true)
            let result = await getAppUser({ _id, startDate, endDate });
            setUserDetail(result.data.user)
            setSales(result.data.sales)
            setSurvey(result.data.survey)
            setEod(result.data.eod)
            setVehicle(result.data.vehicle)
            setQuotation(result.data.quotation)
            setComplaint(result.data.complaint)
            //filter sales

            if (result.data.sales && result.data.sales.length > 0) {
                let csvSalesLocal = []

                result.data.sales.forEach(function (item) {
                    csvSalesLocal.push({
                        "Date": FormatDate(item.date),
                        "Customer Name": item.customerName,
                        "Customer Phone": item.customerPhone,
                        "Customer Address": item.customerAddress,
                        "Project Size": item.projectSize,
                        "Project Value": item.projectValue,
                        "Refer By": item.referBy,
                        "Employee Name": result.data.user.name,
                        "Employee Phone": result.data.user.username,
                        "Sale Status": item.status,
                        "Backend Status": item.backendStatus,
                        "Compliance Status": item.complianceStatus,
                        "Purchase Status": item.purchaseStatus
                    })
                })

                setCsvSales(csvSalesLocal)
            }

            if (result.data.eod && result.data.eod.length > 0) {
                let csvEodLocal = []

                result.data.eod.forEach(function (item) {
                    csvEodLocal.push({


                        "Date": FormatDate(item.date),
                        "Place Visited": item.placeVisited,
                        "Project Name": item.projectData[0]?.projectName,
                        // "Customer Phone": item.customerPhone,
                        // "Previous Balance": item.previousBalance,
                        "Expenses Amount": item.expToday,
                        "Credit Amount": item.creditBalance,
                        // "Balance In Hand": item.balanceCash,
                        "Expense Detail": String(item.expDetail).replace(/,/g, ''),
                        "Status": item.status != undefined ? (item.status ? "Approved" : "Pending") : 'n/a',
                        "Employee Name": result.data.user.name,
                        "Employee Phone": result.data.user.username,

                    })
                })
                console.log(csvEodLocal)

                setCsvEod(csvEodLocal)
            }

            if (result.data.vehicle && result.data.vehicle.length > 0) {
                let csvVehicleLocal = []

                result.data.vehicle.forEach(function (item) {
                    csvVehicleLocal.push({

                        "Date": FormatDate(item.date),
                        "Vehicle Type": item.vehicleType,
                        "Vehicle No.": item.vehicleNo,
                        "Morning Reading": item.morningReading,
                        "Evening Reading": item.eveningReading,
                        "KM Driven": item.kmDriven,
                        "Employee Name": result.data.user.name,
                        "Employee Phone": result.data.user.username,

                    })
                })
                // console.log(csvVehicleLocal)

                setCsvVehicle(csvVehicleLocal)
            }
            if (result.data.quotation && result.data.quotation.length > 0) {
                let csvQuotationLocal = []

                result.data.quotation.forEach(function (item) {
                    csvQuotationLocal.push({

                        "Date": FormatDate(item.date),
                        "Comments": item.comments,
                        "Employee Name": result.data.user.name,
                        "Employee Phone": result.data.user.username,

                    })
                })
                // console.log(csvVehicleLocal)

                setCsvQuotation(csvQuotationLocal)
            }
            if (result.data.complaint && result.data.complaint.length > 0) {
                let csvComplaintLocal = []

                result.data.complaint.forEach(function (item) {
                    csvComplaintLocal.push({

                        "Date": FormatDate(item.date),
                        "Customer Name": item.customerName,
                        "Customer Phone": item.customerPhone,
                        "Customer Address": item.customerAddress,
                        "Project Size": item.projectSize,
                        "Description": item.description,
                        "Status": item.complaintStatus,
                        "Employee Name": result.data.user.name,
                        "Employee Phone": result.data.user.username,

                    })
                })
                // console.log(csvEodLocal)

                setCsvComplaint(csvComplaintLocal)
            }

            setLoading(false)
        }




    }
    if (loading) {
        return <div className="loader text-center mt-5">
            Loading...<br />     <img src={loaderImage} height="50" width="50" />
        </div>
    }
    return (


        <Grid container spacing={3}>

            <Grid container spacing={3} className={classes.gridContainer}>

                <Grid container className={classes.gridHeading}><h4>Employee - {Object.keys(userDetail).length > 0 && userDetail.name + " (" + userDetail.username + ")" + " in " + userDetail.role + " Department"}</h4></Grid>

                {Object.keys(userDetail).length > 0 ?
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>

                            <List className={classes.list} component="nav" aria-label="main mailbox folders">
                                <ListItem >
                                    <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>Name</Typography>} />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText>
                                        {String(userDetail.name).replace("_", " ").toUpperCase()}
                                    </ListItemText>
                                </ListItem>
                            </List>


                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ListItem className={classes.list} component="nav" aria-label="main mailbox folders">
                                <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>Phone</Typography>} />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText>{String(userDetail.username).replace("_", " ").toUpperCase()}</ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={6}>

                            <List className={classes.list} component="nav" aria-label="main mailbox folders">
                                <ListItem>
                                    <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>Email</Typography>} />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText>
                                        {userDetail.email}
                                    </ListItemText>
                                </ListItem>
                            </List>


                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ListItem>
                                <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>Department</Typography>} />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText>{String(userDetail.role).replace("_", " ").toUpperCase()}</ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ListItem>
                                <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>Balance</Typography>} />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText> {userDetail.balance}</ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>
                    : (

                        <h6>
                            No Record Found!
                        </h6>

                    )}
            </Grid>

            <Grid container spacing={3} className={classes.gridContainer}>

                <Grid container className={classes.gridHeading}><h4>Filter Data</h4></Grid>

                <Grid item xs={12} md={4}>

                    <TextField
                        id="date"
                        label="From"
                        type="date"
                        defaultValue={FormatDateSearchFilterString(startDate)}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => { setStartDate(e.target.value) }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>

                    <TextField
                        id="date"
                        label="to"
                        type="date"
                        defaultValue={FormatDateSearchFilterString(endDate)}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => { setEndDate(e.target.value) }}
                    />
                </Grid>

                <Grid item xs={12} md={2}>

                    <Button
                        onClick={() => { getData(true, startDate, endDate) }}
                        variant="contained"
                        color="primary"
                        className={classes.button}

                    ><FilterListIcon style={{ marginRight: 5 }} className={classes.iconGroupCustomer} />Filter</Button>

                </Grid>
                <Grid item xs={12} md={2}>

                    <Button onClick={() => { window.print() }}
                        variant="contained"
                        color="primary"
                        className={[classes.button, classes.buttonPrint]}
                        endIcon={<Icon></Icon>}
                    ><PictureAsPdfIcon style={{ marginRight: 5, }} className={classes.iconGroupCustomer} />Print/Pdf</Button>

                </Grid>
            </Grid>


            {Object.keys(userDetail).length > 0 && userDetail.role === 'sales' &&

                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid container className={classes.gridHeading}>
                        <Grid item xs={12} md={10}><h4>Sales</h4></Grid>
                        <Grid item xs={12} md={2}>

                            <CSVLink data={csvSales} filename={userDetail.name + " (" + userDetail.username + ") - Sale Report from " + FormatDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)) + " to " + FormatDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)) + ".csv"}><Button
                                variant="contained"
                                color="primary"
                                className={[classes.button, classes.buttonExport]}
                                endIcon={<Icon></Icon>}
                            ><GetAppIcon style={{ marginRight: 2, }} className={classes.iconGroupCustomer} />Export</Button></CSVLink>

                        </Grid>
                    </Grid>
                    <Grid item xs={12}>

                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>

                                        <TableCell style={{ fontWeight: 'bold' }} align="left">CustomerName</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="left">Customer Phone</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="left">Customer Address</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="left">Project Size</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="left">Project Value</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="left">Date</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sales.length > 0 && sales.map((sale, key) => {
                                        return <TableRow >

                                            <TableCell align="left"><a href="#" onClick={(e) => { e.preventDefault(); props.history.push('/sale-detail/', { id: sale._id }) }}>{String(sale.customerName).replace("_", " ").toUpperCase()}</a></TableCell>
                                            <TableCell align="left">{String(sale.customerPhone).replace("_", " ").toUpperCase()}</TableCell>
                                            <TableCell align="left">{String(sale.customerAddress).replace("_", " ").toUpperCase()}</TableCell>
                                            <TableCell align="left">{String(sale.projectSize).replace("_", " ").toUpperCase()}</TableCell>
                                            <TableCell align="left">{sale.projectValue}</TableCell>
                                            <TableCell align="left">{FormatDate(sale.date)}</TableCell>

                                        </TableRow>
                                    })}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            }


            {Object.keys(userDetail).length > 0 && userDetail.role === 'survey/installation' &&

                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid container className={classes.gridHeading}>
                        <Grid item xs={12} md={10}><h4>Surveys</h4></Grid>
                        <Grid item xs={12} md={2}>
                            <CSVLink data={csvSales} filename={userDetail.name + " (" + userDetail.username + ") - Survey Report from " + FormatDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)) + " to " + FormatDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)) + ".csv"}><Button
                                variant="contained"
                                color="primary"
                                className={[classes.button, classes.buttonExport]}
                                endIcon={<Icon></Icon>}
                            ><GetAppIcon style={{ marginRight: 2, }} className={classes.iconGroupCustomer} />Export Surveys</Button></CSVLink>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>

                                        <TableCell style={{ fontWeight: 'bold' }} align="left">CustomerName</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="left">Customer Phone</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="left">Customer Address</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="left">Project Size</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="left">Project Value</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="left">Date</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sales.length > 0 && sales.map((sale, key) => {
                                        return <TableRow >

                                            <TableCell align="left"><a href="#" onClick={(e) => { e.preventDefault(); props.history.push('/sale-detail/', { id: sale._id }) }}>{String(sale.customerName).replace("_", " ").toUpperCase()}</a></TableCell>
                                            <TableCell align="left">{String(sale.customerPhone).replace("_", " ").toUpperCase()}</TableCell>
                                            <TableCell align="left">{String(sale.customerAddress).replace("_", " ").toUpperCase()}</TableCell>
                                            <TableCell align="left">{String(sale.projectSize).replace("_", " ").toUpperCase()}</TableCell>
                                            <TableCell align="left">{sale.projectValue}</TableCell>
                                            <TableCell align="left">{FormatDate(sale.date)}</TableCell>

                                        </TableRow>
                                    })}

                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Grid>
                </Grid>

            }

            {Object.keys(userDetail).length > 0 && userDetail.role === 'survey/installation' &&
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid container className={classes.gridHeading}>
                        <Grid item xs={12} md={10}><h4>Installations</h4></Grid>
                        <Grid item xs={12} md={2}>
                            <CSVLink data={csvSales} filename={userDetail.name + " (" + userDetail.username + ") - Installations Report from " + FormatDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)) + " to " + FormatDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)) + ".csv"}><Button
                                variant="contained"
                                color="primary"
                                className={[classes.button, classes.buttonExport]}
                                endIcon={<Icon></Icon>}
                            ><GetAppIcon style={{ marginRight: 2, }} className={classes.iconGroupCustomer} />Export</Button></CSVLink>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>

                                        <TableCell style={{ fontWeight: 'bold' }} align="left">CustomerName</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="left">Customer Phone</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="left">Customer Address</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="left">Project Size</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="left">Project Value</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="left">Date</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sales.length > 0 && sales.map((sale, key) => {
                                        return <TableRow >

                                            <TableCell align="left"><a href="#" onClick={(e) => { e.preventDefault(); props.history.push('/sale-detail/', { id: sale._id }) }}>{String(sale.customerName).replace("_", " ").toUpperCase()}</a></TableCell>
                                            <TableCell align="left">{String(sale.customerPhone).replace("_", " ").toUpperCase()}</TableCell>
                                            <TableCell align="left">{String(sale.customerAddress).replace("_", " ").toUpperCase()}</TableCell>
                                            <TableCell align="left">{String(sale.projectSize).replace("_", " ").toUpperCase()}</TableCell>
                                            <TableCell align="left">{sale.projectValue}</TableCell>
                                            <TableCell align="left">{FormatDate(sale.date)}</TableCell>

                                        </TableRow>
                                    })}

                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Grid>
                </Grid>



            }





            <Grid container spacing={3} className={classes.gridContainer}>
                <Grid container className={classes.gridHeading}>
                    <Grid item xs={12} md={10}><h4>EOD's</h4></Grid>

                    <Grid item xs={12} md={2}>
                        <CSVLink data={csvEod} filename={userDetail.name + " (" + userDetail.username + ") - EOD's Report from " + FormatDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)) + " to " + FormatDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)) + ".csv"}><Button
                            variant="contained"
                            color="primary"
                            className={[classes.button, classes.buttonExport]}
                            endIcon={<Icon></Icon>}
                        ><GetAppIcon style={{ marginRight: 2, }} className={classes.iconGroupCustomer} />Export</Button></CSVLink>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>

                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Place Visited</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Project Name</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Expenses Amount</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Credit Amount</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Date</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Status</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {eods.length > 0 && eods.map((eod, key) => {
                                    return <TableRow >

                                        <TableCell align="left"><a href="#" onClick={(e) => { e.preventDefault(); props.history.push('/eod-detail/', { id: eod._id }) }}>{String(eod.placeVisited || 'n/a').replace("_", " ").toUpperCase()}</a></TableCell>
                                        <TableCell align="left">{eod.projectData.length ? eod?.projectData[0].projectName : "n/a"}</TableCell>
                                        <TableCell align="left">{String(eod.expToday || 'n/a').replace("_", " ").toUpperCase()}</TableCell>
                                        <TableCell align="left">{String(eod.creditBalance || 'n/a').replace("_", " ").toUpperCase()}</TableCell>
                                        <TableCell align="left">{FormatDate(eod.date)}</TableCell>
                                        <TableCell align="left"><Switch
                                            disabled={true}
                                            checked={eod.status} /></TableCell>

                                    </TableRow>
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>


            <Grid container spacing={3} className={classes.gridContainer}>
                <Grid container className={classes.gridHeading}>

                    <Grid item xs={12} md={10}><h4>Vehicle Daily Entries</h4></Grid>
                    <Grid item xs={12} md={2}>
                        <CSVLink data={csvVehicle} filename={userDetail.name + " (" + userDetail.username + ") - Vehicle Entries Report from " + FormatDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)) + " to " + FormatDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)) + ".csv"}><Button
                            variant="contained"
                            color="primary"
                            className={[classes.button, classes.buttonExport]}
                            endIcon={<Icon></Icon>}
                        ><GetAppIcon style={{ marginRight: 2, }} className={classes.iconGroupCustomer} />Export</Button></CSVLink>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>

                                    <TableCell align="left">Vehicle Type</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Vehicle No.</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Morning Reading</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Evening Reading</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">KM Driven</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {vehicles.length > 0 && vehicles.map((vehicle, key) => {
                                    return <TableRow >

                                        <TableCell align="left"><a href="#" onClick={(e) => { e.preventDefault(); props.history.push('/vehicle-detail/', { id: vehicle._id }) }}>{String(vehicle?.vehicleType || 'n/a').replace("_", " ").toUpperCase()}</a></TableCell>
                                        <TableCell align="left">{String(vehicle?.vehicleNo || 'n/a').replace("_", " ").toUpperCase()}</TableCell>
                                        <TableCell align="left">{vehicle?.eveningReading || 'n/a'}</TableCell>
                                        <TableCell align="left">{vehicle?.morningReading || 'n/a'}</TableCell>
                                        <TableCell align="left">{vehicle?.kmDriven || 'n/a'}</TableCell>
                                        <TableCell align="left">{FormatDate(vehicle.date)}</TableCell>
                                    </TableRow>

                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            <Grid container spacing={3} className={classes.gridContainer}>
                <Grid container className={classes.gridHeading}>
                    <Grid item xs={12} md={10}><h4>Quotations Requests</h4></Grid>

                    <Grid item xs={12} md={2}>
                        <CSVLink data={csvQuotation} filename={userDetail.name + " (" + userDetail.username + ") - Quotations Requests Report from " + FormatDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)) + " to " + FormatDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)) + ".csv"}><Button
                            variant='contained'
                            color="primary"
                            className={[classes.button, classes.buttonExport]}
                            endIcon={<Icon></Icon>}
                        ><GetAppIcon style={{ marginRight: 2, }} className={classes.iconGroupCustomer} />Export</Button></CSVLink>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Comments</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {quotations.length > 0 && quotations.map((quotation, key) => {
                                    return <TableRow >
                                        <TableCell align="left">{String(quotation?.comments || 'n/a').replace("_", " ").toUpperCase()}</TableCell>
                                        <TableCell align="left">{FormatDate(quotation.date)}</TableCell>

                                    </TableRow>
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>



            <Grid container spacing={3} className={classes.gridContainer}>
                <Grid container className={classes.gridHeading}>
                    <Grid item xs={12} md={10}><h4>Complaints</h4></Grid>

                    <Grid item xs={12} md={2}>
                        <CSVLink data={csvComplaint} filename={userDetail.name + " (" + userDetail.username + ") - Complaints Report from " + FormatDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)) + " to " + FormatDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)) + ".csv"}><Button
                            variant="contained"
                            color="primary"
                            className={[classes.button, classes.buttonExport]}
                            endIcon={<Icon></Icon>}
                        ><GetAppIcon style={{ marginRight: 2, }} className={classes.iconGroupCustomer} />Export</Button></CSVLink>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>

                                    <TableCell style={{ fontWeight: 'bold' }} align="left">CustomerName</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Customer Phone</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Customer Address</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Project Size</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Status</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} align="left">Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {complaints.length > 0 && complaints.map((complaint, key) => {
                                    return <TableRow >

                                        <TableCell align="left"><a href="#" onClick={(e) => { e.preventDefault(); props.history.push('/complaint-detail/', { id: complaint._id }) }}>{String(complaint?.customerName || 'n/a').replace("_", " ").toUpperCase()}</a></TableCell>
                                        <TableCell align="left">{String(complaint?.customerPhone || 'n/a').replace("_", " ").toUpperCase()}</TableCell>
                                        <TableCell align="left">{String(complaint?.customerAddress || 'n/a').replace("_", " ").toUpperCase()}</TableCell>
                                        <TableCell align="left">{String(complaint?.projectSize || 'n/a').replace("_", " ").toUpperCase()}</TableCell>
                                        <TableCell align="left">{String(complaint?.complaintStatus || 'n/a').replace("_", " ").toUpperCase()}</TableCell>

                                        <TableCell align="left">{FormatDate(complaint.date)}</TableCell>

                                    </TableRow>

                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>



        </Grid >
    );
}
