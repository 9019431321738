import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { getAppUser } from '../../../services/appUser'
import { saleDetailsService } from '../../../services/sales'
import ImageGallery from 'react-grid-gallery';

import { getUserInfo, getUserId } from './../../../helpers/authentication'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import loaderImage from "../../../assets/loader1.svg";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import { FormatDateSearchFilter, FormatDateSearchFilterString, FormatDate } from '../../../helpers/authentication'
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    grid: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#fff',
        cursor: 'pointer',
        borderRadius: 10
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
    },
    gridContainer: {
        padding: 15,
        backgroundColor: '#fff',
        margin: 10,
        borderRadius: 10
    }, gridHeading: {
        padding: '5px 15px',
        backgroundColor: '#fff',
        margin: 5,
        borderRadius: 10
    },
    gridContainerBottom: {

        flexDirection: 'column',
        display: 'flex',

        backgroundColor: '#fff'


    },
    divTextContainerBottom: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 35
    },
    textPerformance: {
        color: '#009330', fontWeight: 'bold', fontSize: 25, textAlign: 'flex-start', paddingBottom: 20, paddingLeft: 10, textAlign: 'flex-start'
    },
    textMonth: {
        color: '#009330', fontWeight: 'bold', fontSize: 25, paddingLeft: 15, textAlign: 'flex-end'
    },
    button: {
        width: '100%',
        height: 40
    },
    buttonExport: {
        backgroundColor: '#004500'
    },


}));

export default function SaleDetails(props) {
    const classes = useStyles();
    const [userDetail, setUserDetail] = useState({});
    const [salesDetails, setSalesDetails] = useState([]);
    const [images, setImages] = useState([]);
    const [surveys, setSurveys] = useState([]);
    const [installations, setInstallations] = useState([]);
    const [loading, setLoading] = useState(true);

    const [startDate, setStartDate] = useState(FormatDateSearchFilter(new Date(new Date().getFullYear(), new Date().getMonth(), 1)))

    const [endDate, setEndDate] = useState(FormatDateSearchFilter(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)))

    useEffect(() => {
        getData(true)
    }, [])

    const getData = async (refresh = false,) => {
        const _id = props?.location?.state?.id || false

        if (_id) {
            setLoading(true)
            let result = await saleDetailsService({ _id });
            setLoading(false)
            setSalesDetails(result.data.result)

            let imagesLocal = []
            let surveysLocal = []
            let installationLocal = []

            if (result.data.result.adharImage) {
                imagesLocal.push({
                    src: result.data.result.adharImage,
                    thumbnail: result.data.result.adharImage,
                    caption: "Adhar Card",
                    thumbnailWidth: 320,
                    thumbnailHeight: 174,

                })
            }

            if (result.data.result.panCardImage) {
                imagesLocal.push({
                    src: result.data.result.panCardImage,
                    thumbnail: result.data.result.panCardImage,
                    caption: "Pan Card",
                    thumbnailWidth: 320,
                    thumbnailHeight: 174,

                })
            }

            if (result.data.result.passportSizePhotoImage) {
                imagesLocal.push({
                    src: result.data.result.passportSizePhotoImage,
                    thumbnail: result.data.result.passportSizePhotoImage,
                    caption: "Passport Size Photo",
                    thumbnailWidth: 320,
                    thumbnailHeight: 174,

                })
            }

            if (result.data.result.electricityBillImage) {
                imagesLocal.push({
                    src: result.data.result.electricityBillImage,
                    thumbnail: result.data.result.electricityBillImage,
                    caption: "Electricity Bill",
                    thumbnailWidth: 320,
                    thumbnailHeight: 174,

                })
            }

            if (result.data.extraFiles) {
                result.data.extraFiles.forEach(function (item) {
                    item.files && item.files.length > 0 && item.files.forEach(function (file) {
                        imagesLocal.push({
                            src: file,
                            thumbnail: file,
                            caption: "Extra File",
                            thumbnailWidth: 320,
                            thumbnailHeight: 174,

                        })
                    })
                })
            }

            setImages(imagesLocal)


            if (result.data.surveyPerforma) {
                result.data.surveyPerforma.forEach(function (item) {
                    item.surveyPerforma && item.surveyPerforma.length > 0 && item.surveyPerforma.forEach(function (file) {
                        surveysLocal.push({
                            src: file,
                            thumbnail: file,
                            caption: item.surveyComments,
                            thumbnailWidth: 320,
                            thumbnailHeight: 174,

                        })
                    })
                })
            }
            setSurveys(surveysLocal)

            if (result.data.installationPerforma) {
                result.data.installationPerforma.forEach(function (item) {
                    item.installationPerforma && item.installationPerforma.length > 0 && item.installationPerforma.forEach(function (file) {
                        installationLocal.push({
                            src: file,
                            thumbnail: file,
                            caption: item.installationComments,
                            thumbnailWidth: 320,
                            thumbnailHeight: 174,

                        })
                    })
                })
            }
            setInstallations(installationLocal)


        }


    }

    return (


        <Grid container spacing={3}>


            <Grid container spacing={3} className={classes.gridContainer}>

                <Grid container className={classes.gridHeading}><h5>Status</h5></Grid>
                {Object.keys(salesDetails).length > 0 ?
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>

                            <List className={classes.list} component="nav" aria-label="main mailbox folders">
                                <ListItem>
                                    <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>Status</Typography>} />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText>
                                        {String(salesDetails.status || 'n/a').replace("_", " ").toUpperCase()}

                                    </ListItemText>
                                </ListItem>
                            </List>


                        </Grid>
                        <Grid item xs={12} md={3}>

                            <List className={classes.list} component="nav" aria-label="main mailbox folders">
                                <ListItem>
                                    <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>Backend Status</Typography>} />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText>
                                        {String(salesDetails.backendStatus || 'n/a').replace("_", " ").toUpperCase()}

                                    </ListItemText>
                                </ListItem>
                            </List>


                        </Grid>
                        <Grid item xs={12} md={3}>
                            <ListItem>
                                <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}
                                >Compliance Status</Typography>} />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText>
                                    {String(salesDetails.complianceStatus || 'n/a').replace("_", " ").toUpperCase()}</ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <ListItem>
                                <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>Purchase Status</Typography>} />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText>{String(salesDetails.purchaseStatus || 'n/a').replace("_", " ").toUpperCase()}</ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>
                    : (

                        <h6>
                            No Record Found!
                        </h6>

                    )}
            </Grid>


            <Grid container spacing={3} className={classes.gridContainer}>

                <Grid container className={classes.gridHeading}><h5>Sale Details</h5></Grid>
                {Object.keys(salesDetails).length > 0 ?
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>

                            <List className={classes.list} component="nav" aria-label="main mailbox folders">
                                <ListItem>
                                    <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>Customer Name</Typography>} />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText>
                                        {salesDetails.customerName}
                                    </ListItemText>
                                </ListItem>
                            </List>


                        </Grid>
                        <Grid item xs={12} md={6}>

                            <List className={classes.list} component="nav" aria-label="main mailbox folders">
                                <ListItem>
                                    <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>Customer Phone</Typography>} />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText>
                                        {String(salesDetails.customerPhone || 'n/a').replace("_", " ").toUpperCase()}
                                    </ListItemText>
                                </ListItem>
                            </List>


                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ListItem>
                                <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}
                                >Customer Address</Typography>} />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText>{String(salesDetails.customerAddress || 'n/a').replace("_", " ").toUpperCase()}</ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ListItem>
                                <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>Project Size</Typography>} />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText>{String(salesDetails.projectSize || 'n/a').replace("_", " ").toUpperCase()}</ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ListItem>
                                <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>Project Value</Typography>} />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText>{String(salesDetails.projectValue || 'n/a').replace("_", " ").toUpperCase()}</ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ListItem>

                                <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>Date</Typography>} />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText>{FormatDate(salesDetails.date)}</ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>
                    : (

                        <h6>
                            No Record Found!
                        </h6>

                    )}
            </Grid>


            <Grid container spacing={3} className={classes.gridContainer}>

                <Grid container className={classes.gridHeading}><h5>Attached Images</h5></Grid>

                <Grid container spacing={3}>

                    <Grid item xs={12} md={12}>

                        <ImageGallery images={images} margin={20} enableImageSelection={false} />


                    </Grid>
                </Grid>
            </Grid>

            {surveys.length > 0 &&
                <Grid container spacing={3} className={classes.gridContainer}>

                    <Grid container className={classes.gridHeading}><h5>Survey Performa</h5></Grid>

                    <Grid container spacing={3}>

                        <Grid item xs={12} md={12}>

                            <ImageGallery images={surveys} margin={20} enableImageSelection={false} />


                        </Grid>
                    </Grid>
                </Grid>
            }

            {installations.length > 0 &&
                <Grid container spacing={3} className={classes.gridContainer}>

                    <Grid container className={classes.gridHeading}><h5>Installation Photos</h5></Grid>

                    <Grid container spacing={3}>

                        <Grid item xs={12} md={12}>

                            <ImageGallery images={installations} margin={20} enableImageSelection={false} />


                        </Grid>
                    </Grid>
                </Grid>
            }


        </Grid >
    );
}
