import React from 'react'
import ecoglobal from '../../assets/logo.jpg';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider';
import MailIconGroup from '@material-ui/icons/Group';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ReportIcon from '@material-ui/icons/Report';
import CommuteIcon from '@material-ui/icons/Commute';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ForumIcon from '@material-ui/icons/Forum';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles((theme) => ({
    homeIcon: {
        color: '#005ab3'
    },
    iconGroupComplaint: {
        fontSize: 25,
        color: '#b30000',

    },
    iconGroupSales: {
        fontSize: 25,
        color: '#90ee90',

    },
    iconGroupEod: {
        fontSize: 25,
        color: '#ff4dff',

    },
    iconGroupCustomer: {
        fontSize: 25,
        color: '#005ab3',

    },
    iconGroupVehicle: {
        fontSize: 25,
        color: '#ff8000',

    }, iconGroupQuotation: {
        fontSize: 25,
        color: '#ffa500',

    },
    iconGroup: {
        fontSize: 25,
        color: '#006200',

    },
    iconGroupLogout: {
        color: '#ff0000'
    }
}));

const Sidebar = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <>
            {/* <div>
                <img src={ecoglobal} height="150" width="150" />
            </div> */}


            <List>
                <ListItem button onClick={(e) => { props.navigation(e, '/') }}

                >
                    <ListItemIcon><HomeIcon className={classes.homeIcon} /></ListItemIcon>
                    <ListItemText primary={"Dashboard"} />
                </ListItem>
                <ListItem button onClick={(e) => { props.navigation(e, '/employees/') }}

                >
                    <ListItemIcon><MailIconGroup className={classes.iconGroup} /></ListItemIcon>
                    <ListItemText primary={"Employees"} />
                </ListItem>
                <ListItem button onClick={(e) => { props.navigation(e, '/customers/') }}

                >
                    <ListItemIcon ><PermIdentityIcon className={classes.iconGroupCustomer} /></ListItemIcon>
                    <ListItemText primary={"Customers"} />
                </ListItem>
                <ListItem button onClick={(e) => { props.navigation(e, '/sales/') }}

                >
                    <ListItemIcon ><MonetizationOnIcon className={classes.iconGroupSales} /></ListItemIcon>
                    <ListItemText primary={"Sales"} />
                </ListItem>
                <ListItem button onClick={(e) => { props.navigation(e, '/eod/') }}

                >
                    <ListItemIcon ><ReportIcon className={classes.iconGroupEod} /></ListItemIcon>
                    <ListItemText primary={"Eod"} />
                </ListItem>
                <ListItem button onClick={(e) => { props.navigation(e, '/vehicle/') }}

                >
                    <ListItemIcon ><CommuteIcon className={classes.iconGroupVehicle} /></ListItemIcon>
                    <ListItemText primary={"Vehicles Detail"} />
                </ListItem>
                <ListItem button onClick={(e) => { props.navigation(e, '/quotation/') }}

                >
                    <ListItemIcon ><LocalOfferIcon className={classes.iconGroupQuotation} /></ListItemIcon>
                    <ListItemText primary={"Quotations"} />
                </ListItem>
                <ListItem button onClick={(e) => { props.navigation(e, '/complaint/') }}

                >
                    <ListItemIcon ><ForumIcon className={classes.iconGroupComplaint} /></ListItemIcon>
                    <ListItemText primary={"Complaints"} />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button onClick={(e) => { props.logout(e) }}


                >
                    <ListItemIcon><ExitToAppIcon className={classes.iconGroupLogout} /></ListItemIcon>
                    <ListItemText primary={"LogOut"} />
                </ListItem>
            </List>

            {/* <ul id="myMenu">
                <li><button type="button" className="btn btn-primary" onClick={(e)=>{props.navigation(e,'/admin/')}}>Dashboard</button></li>
                <li><button type="button" className="btn btn-primary" ></button></li>
                <li><button type="button" className="btn btn-primary" ></button></li>
                <li><button type="button" className="btn btn-primary" >Sales</button></li>
                <li><button type="button" className="btn btn-primary" >Eod's</button></li>
                <li><button type="button" className="btn btn-primary" >Vehicle Details</button></li>
                <li><button type="button" className="btn btn-primary" >Quotations</button></li>
                <li><button type="button" className="btn btn-primary" >Complaints</button></li>
                <li><button type="button" className="btn btn-primary" onClick={(e) => { props.navigation(e, '/admin/welfare/') }}>Welfare Society</button></li>
                <li><button type="button" className="btn btn-primary" onClick={props.logout}>Logout</button></li>
            </ul> */}
        </>
    )
}

export default Sidebar
