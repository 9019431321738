import axios from 'axios'
import { getToken } from '../../helpers/authentication'
// Add a request interceptor
axios.defaults.headers.common['Authorization'] = getToken();



export const allEod = (data) => axios.post('/api/eod/view-all', data)

export const getEodDetailService = (data) => axios.post('/api/eod/get', data)

export const getAllProjects = (data) => axios.post('/api/project/view-all', data)

export const apiApproveEod = (data) => axios.post('/api/eod/approve-eod', data)