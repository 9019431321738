import axios from 'axios'
import { getToken } from '../../helpers/authentication'
// Add a request interceptor
axios.defaults.headers.common['Authorization'] = getToken();



export const allVehicleService = (data) => axios.post('/api/vehicle/view-all', data)


export const getVehicleDetailService = (data) => axios.post('/api/vehicle/get', data)
