import React from 'react'
import Footer from '../../components/admin/footer/index.js'
import ecoglobal from '../../assets/logo.jpg'

import { LoginService } from './../../services/admin/user'

import { saveUserInfo } from './../../helpers/authentication'

class Login extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            username: "",
            password: "",
            errors: {
                username: "",
                password: "",
            },
            errorMsg: ""
        }

        this.handleInput = this.handleInput.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    handleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onSubmit = (e) => {
        e.preventDefault();
        // validation
        const { username, password } = this.state

        const user = {
            username,
            password
        }

        let errors = { ...this.state.errors }
        let validate = true


        if (username.trim() === "") {
            errors.username = "Please enter username"
            validate = false
        }
        if (password.trim() === "") {
            errors.password = "Please enter password"
            validate = false
        }

        this.setState({ errors })

        if (validate) {

            LoginService(user)
                .then(res => {

                    if (res.data.status === 'failed') {
                        this.setState({ errorMsg: res.data.message })
                        return
                    }

                    if (res.data.status) {

                        if (res.data.result.role !== 'admin' && res.data.result.role !== 'super-admin' && res.data.result.role !== 'backend') {
                            this.setState({ errorMsg: 'You are not allowed to login to web portal' })
                            return
                        }
                        saveUserInfo(res.data)
                        // this.props.history.push("/")
                        window.location.reload()
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }

    render() {
        return (
            <div className="container">
                {/* HEADER */}
                <div class="row">
                    <div class="col text-center">
                        <img src={ecoglobal} /></div>
                </div>


                <form onSubmit={this.onSubmit} >
                    <div class="row loginForm">
                        <div class="col-md-3 col-xs-0">

                        </div>
                        <div class="col-md-6 col-xs-12">

                            {this.state.errorMsg &&
                                <div class="alert alert-danger" role="alert">
                                    {this.state.errorMsg}
                                </div>
                            }

                            <div className="loginBorder">

                                <h3>Admin Login</h3>


                                <div className="form-group" style={{ paddingBlock: '20px', }}>
                                    <label htmlFor="username">Username</label>
                                    <input type="text" className="form-control" id="username" aria-describedby="emailHelp" placeholder="Enter Username" name="username" onChange={this.handleInput} />

                                    {this.state.errors.username &&
                                        <div class="alert alert-danger" role="alert">
                                            {this.state.errors.username}
                                        </div>
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input type="password" className="form-control" id="password" placeholder="Enter Password" name="password" onChange={this.handleInput} />
                                    {this.state.errors.password &&
                                        <div class="alert alert-danger" role="alert">
                                            {this.state.errors.password}
                                        </div>
                                    }
                                </div>
                                <button type="submit" className="btn btn-primary">Login</button>
                            </div>

                        </div>
                        <div class="col-md-3 col-xs-0">

                        </div>

                    </div>

                </form>
                <div>
                    <div>
                        <Footer />
                    </div>
                </div>

            </div>

        )
    }
}

export default Login
