import React from 'react'
import { Link } from 'react-router-dom'
import "../../../assets/_footer.scss";
// import {androidlogo} from "../../containers/assets/images/androidlogo.png";

const Footer = () => {
	return (
		<div>
			<footer>
				<div className="container">
					<div className="row">
						<div className="col-md-3 col-12">
							<div className="widget widget-about">
								{/* <div className="logo-ft">
								<a href='https://play.google.com/store/apps/details?id=com.secnat'>
								<button className="btn btn-primary" title="">
								We4U- Stay Connected.Stay Safe!
								</button>
								</a>
							</div> */}
								<p>

								</p>
							</div>
						</div>
						<div className="col-md-3 col-12">
							<div className="widget widget-categories">
								{/* <h3 className="widget-title"></h3> */}
								<div className="row">
									<ul className="one-half col-md-15">


									</ul>

									<ul className="one-half col-md-6">

										{/* <li>
									<Link to="explore-jobs" title="">Jobs</Link>
								</li>
								<li>
									<Link to="explore-resume" title="">Resumes</Link>
								</li>
								<li>
									<Link to="matrimonial-profiles" title="">Find Dream Partner</Link>
								</li> */}
									</ul>
								</div>
								<div className="clearfix"></div>
							</div>
						</div>
						{/* <div className="col-md-3 col-12">
						<div className="widget widget-contact">
							<h3 className="widget-title">Contact</h3>
							<ul className="contact-infomation">
								<li className="address">
									SCF 32,Mega Market ,Sec-125,Kharar,Mohali(India)
								</li>
								<li className="phone">
									(91) 91 5085 5085
								</li>
								<li className="email">
									contact@fyndgo.com
								</li>
							</ul>
						</div>
					</div> */}
						<div className="col-md-3 col-12">
							{/* <div className="widget widget-map">
							<img src={androidlogo} height="150" width="150" alt=""/>

						</div> */}
							<div className="widget widget-map">
								{/* <img src="images/page/bg-ft.png" alt="" /> */}

							</div>

						</div>
					</div>
				</div>
			</footer>

			<div className="footer-bottom">
				<div className="container">
					<div className="row">

						<div className=" col-6">
							© 2020 Copyright . All rights reserved @ Egsolar
						</div>

						<div className="col-6 text-right">
							<Link to="/privacy-policy" title="">Privacy Policy</Link>
						</div>
					</div>

				</div>
			</div>

		</div>
	);

};

export default Footer;
