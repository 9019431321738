import axios from 'axios'
import { getToken } from '../../helpers/authentication'
// Add a request interceptor
axios.defaults.headers.common['Authorization'] = getToken();



export const allQuotationService = (data) => axios.post('/api/quotation/view-all', data)


