import React, { useState, useEffect } from 'react'
import { getUserInfo } from './../../../helpers/authentication'
import Sidebar from '../../../components/admin/sidebar'
import Switch from "react-switch";
import { allComplaintService } from '../../../services/complaint'
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FormatDateSearchFilter, FormatDateSearchFilterString, FormatDate } from '../../../helpers/authentication'
import loaderImage from "../../../assets/loader1.svg";
import ReportIcon from '@material-ui/icons/Report';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import FilterListIcon from '@material-ui/icons/FilterList';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import CommuteIcon from '@material-ui/icons/Commute';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'; import { CSVLink } from "react-csv";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import Paper from '@material-ui/core/Paper';
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    grid: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#fff',
        cursor: 'pointer',
        borderRadius: 10
    },

    button: {
        width: '100%',
        height: 40
    },
    buttonExport: {
        backgroundColor: '#218C72',
        marginRight: 22,
        '&:hover': {

            backgroundColor: '#218000',
        }
    },
    gridContainer: {
        padding: 15,
        backgroundColor: '#fff',
        margin: 10,
        borderRadius: 10
    },
    gridHeading: {
        // padding: '5px 15px',
        backgroundColor: '#fff',
        margin: 5,
        borderRadius: 10
    },
    button: {
        width: '100%',
        height: 40
    },
    buttonExport: {
        backgroundColor: '#218C72',
        marginRight: 22,
        '&:hover': {

            backgroundColor: '#218000',
        }
    },
    buttonPrint: {
        backgroundColor: '#D02657',
        '&:hover': {

            backgroundColor: '#D02000',
        }
    },
});

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

function Sales(props) {

    const classes = useStyles();

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState('')
    const [csvComplaint, setCsvComplaint] = useState([])

    const [startDate, setStartDate] = useState(FormatDateSearchFilter(new Date(new Date().getFullYear(), new Date().getMonth(), 1)))

    const [endDate, setEndDate] = useState(FormatDateSearchFilter(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)))

    useEffect(() => {
        getData(true)

    }, [])

    const getData = async (refresh = false, search = "", startDate, endDate) => {
        setLoading(true)

        let result = await allComplaintService({ search, startDate, endDate });
        setList(result.data.result)

        if (result.data.result && result.data.result.length > 0) {
            let csvComplaintLocal = []

            result.data.result.forEach(function (item) {
                csvComplaintLocal.push({
                    "Employee Name": item.userData[0].name,
                    "Employee Phone": item.userData[0].username,

                    "Customer Name": item.customerName,
                    "Customer Phone": item.customerPhone,
                    "Customer Address": item.customerAddress,
                    "Project Size": item.projectSize,
                    "Description": item.description,
                    "Status": item.complaintStatus,
                    "Date": FormatDate(item.date),
                    // "Employee Name": result.data.user.name,
                    // "Employee Phone": result.data.user.username,

                })
            })
            // console.log(csvEodLocal)

            setCsvComplaint(csvComplaintLocal)
        }
        setLoading(false)

    }













    // const deleteAppUser = (e, _id) => {
    //     e.preventDefault();


    //     let sure = window.confirm("Are you sure to want to delete?")

    //     if (sure) {

    //         const user = {
    //             _id

    //         }
    //         deleteAppUserServices(user)
    //             .then(res => {
    //                 if (res.data.status === 'success') {

    //                     const list = [...this.state.list]

    //                     const listFilter = list.filter(list => list._id !== _id)

    //                     setList(listFilter)
    //                 }
    //             })
    //             .catch(err => {
    //                 console.error(err)
    //             })

    //     }
    // }

    if (loading) {
        return <div className="loader text-center mt-5">
            Loading...<br />     <img src={loaderImage} height="50" width="50" />
        </div>
    }

    return (
        <>
            <Grid container spacing={3}>

                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid container className={classes.gridHeading}><h4>Filter Result</h4></Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>

                            <TextField id="filled-basic" label="Search..." style={{ width: '100%' }} />

                        </Grid>
                        <Grid item xs={12} md={2}>

                            <TextField
                                id="date"
                                style={{ width: '100%' }}
                                label="From"
                                type="date"
                                style={{ color: '#fff' }}
                                defaultValue={FormatDateSearchFilterString(startDate)}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => { setStartDate(e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>

                            <TextField
                                id="date"
                                label="to"
                                type="date"
                                defaultValue={FormatDateSearchFilterString(endDate)}
                                className={classes.textField}
                                style={{ width: '100%' }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => { setEndDate(e.target.value) }}
                            />
                        </Grid>

                        <Grid item xs={12} md={2}>

                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}

                                onClick={() => { getData(true, startDate, endDate) }}
                                endIcon={<Icon></Icon>}
                            ><FilterListIcon style={{ marginRight: 5 }} className={classes.iconGroupCustomer} />Filter</Button>

                        </Grid>

                        <Grid item xs={12} md={2}>

                            <Button onClick={() => { window.print() }}
                                variant="contained"
                                color="primary"
                                className={[classes.button, classes.buttonPrint]}
                                endIcon={<Icon></Icon>}
                            ><PictureAsPdfIcon style={{ marginRight: 5, }} className={classes.iconGroupCustomer} />Print/Pdf</Button>

                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid container className={classes.gridHeading}>
                        <Grid item xs={12} md={10}><h4>Complaint List</h4></Grid>
                        <Grid item xs={12} md={2}>

                            <CSVLink data={csvComplaint} filename={" Complaint Report from " + FormatDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)) + " to " + FormatDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)) + ".csv"}><Button
                                variant="contained"
                                color="primary"
                                className={[classes.button, classes.buttonExport]}
                                endIcon={<Icon></Icon>}
                            ><GetAppIcon style={{ marginRight: 2, }} className={classes.iconGroupCustomer} />Export</Button></CSVLink>

                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>

                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left"><strong>Employee Name</strong></TableCell>
                                            <TableCell align="left"><strong>Employee Phone</strong></TableCell>
                                            <TableCell align="left"><strong>Department</strong></TableCell>
                                            <TableCell><strong>Customer Name</strong></TableCell>
                                            <TableCell align="left"><strong>Customer Phone</strong></TableCell>
                                            <TableCell align="left"><strong>Customer Address</strong></TableCell>
                                            <TableCell align="left"><strong>Project Size</strong></TableCell>
                                            <TableCell align="left"><strong>Status</strong></TableCell>
                                            <TableCell align="left"><strong>Date</strong></TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {list.length > 0 ? list.map((complaint, key) => {
                                            return <TableRow key={key}>
                                                <TableCell align="left"><a href="#" onClick={(e) => { e.preventDefault(); props.history.push('/complaint-detail/', { id: complaint._id }) }}>{String(complaint.userData[0].name).replace("_", " ").toUpperCase()}</a></TableCell>
                                                <TableCell align="left"><a href="#" onClick={(e) => { e.preventDefault(); props.history.push('/complaint-detail/', { id: complaint._id }) }}>{String(complaint.userData[0].username).replace("_", " ").toUpperCase()}</a></TableCell>
                                                <TableCell align="left"><a href="#" onClick={(e) => { e.preventDefault(); props.history.push('/complaint-detail/', { id: complaint._id }) }}>{String(complaint.userData[0].role).replace("_", " ").toUpperCase()}</a></TableCell>
                                                <TableCell align="left"><a href="#" onClick={(e) => { e.preventDefault(); props.history.push('/complaint-detail/', { id: complaint._id }) }}>{String(complaint.customerName || 'n/a').replace("_", " ").toUpperCase()}</a></TableCell>
                                                <TableCell align="left"><a href="#" onClick={(e) => { e.preventDefault(); props.history.push('/complaint-detail/', { id: complaint._id }) }}>{complaint.customerPhone || 'n/a'}</a></TableCell>
                                                <TableCell align="left">{String(complaint.customerAddress || 'n/a').replace("_", " ").toUpperCase()}</TableCell>
                                                <TableCell align="left">{String(complaint.projectSize || 'n/a').replace("_", " ").toUpperCase()}</TableCell>
                                                <TableCell align="left">{String(complaint.complaintStatus || 'n/a').replace("_", " ").toUpperCase()}</TableCell>
                                                <TableCell align="left">{FormatDate(complaint.date)}</TableCell>
                                            </TableRow>
                                        })
                                            :
                                            <TableRow>
                                                <TableCell align="center" colSpan={6}>
                                                    Record Not Found!
                                </TableCell>
                                            </TableRow>
                                        }


                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )

}
export default Sales;


