import axios from 'axios'
import { getToken } from '../../helpers/authentication'
// Add a request interceptor
axios.defaults.headers.common['Authorization'] = getToken();



export const allComplaintService = (data) => axios.post('/api/complaint/view-all', data)


export const getComplaintDetailService = (data) => axios.post('/api/complaint/get', data)
