import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Login from '../pages/admin/Login'
import Dashboard from '../pages/admin/Dashboard'
import AppUser from '../pages/admin/appUser/index.js';
import Sales from '../pages/admin/sales/index.js';
import Vehicle from '../pages/admin/vehicle/index.js';
import Quotation from '../pages/admin/quotation/index.js';
import Complaint from '../pages/admin/complaint/index.js';
import SaleDetails from '../pages/admin/salesDetail/index.js';
import EodDetails from '../pages/admin/eodDetails/index.js';
import ComplaintDetail from '../pages/admin/complaintDetail/index.js';
import VehicleDetail from '../pages/admin/vehicleDetail/index.js';
import Eod from '../pages/admin/eod/index.js';
import Customers from '../pages/admin/customers/index.js';
import { isUserLoggedIn } from '../helpers/authentication'
import Privacy from '../pages/admin/privacy/index.js'
import Wrapper from '../pages/admin/Wrapper'
import UserDetails from '../pages/admin/detail'
// import '../assets/app.scss'

const routes = () => {
    return (
        <Router>
            <Switch>
                <Route path="/employees/" name="appUser" render={(props = this.props) => {
                    return isUserLoggedIn() ?

                        <Wrapper {...props} title="Employees" >

                            <AppUser {...props} />

                        </Wrapper>

                        :
                        <Login {...props} />
                }} />
                <Route path="/user-detail/" name="detail" render={(props = this.props) => {
                    return isUserLoggedIn() ?

                        <Wrapper {...props} title="User Details" >

                            <UserDetails {...props} />

                        </Wrapper>

                        :
                        <Login {...props} />
                }} />
                <Route path="/sale-detail/" name="SaleDetails" render={(props = this.props) => {
                    return isUserLoggedIn() ?

                        <Wrapper {...props} title="Sale Details" >

                            <SaleDetails {...props} />

                        </Wrapper>

                        :
                        <Login {...props} />
                }} />
                <Route path="/eod-detail/" name="EodDetails" render={(props = this.props) => {
                    return isUserLoggedIn() ?

                        <Wrapper {...props} title="EOD Details" >

                            <EodDetails {...props} />

                        </Wrapper>

                        :
                        <Login {...props} />
                }} />

                <Route path="/complaint-detail/" name="ComplaintDetail" render={(props = this.props) => {
                    return isUserLoggedIn() ?

                        <Wrapper {...props} title="Complaint Details" >

                            <ComplaintDetail {...props} />

                        </Wrapper>

                        :
                        <Login {...props} />
                }} />
                <Route path="/vehicle-detail/" name="VehicleDetail" render={(props = this.props) => {
                    return isUserLoggedIn() ?

                        <Wrapper {...props} title="Vehicle Details" >

                            <VehicleDetail {...props} />

                        </Wrapper>

                        :
                        <Login {...props} />
                }} />

                <Route path="/customers/" name="customers" render={(props = this.props) => {
                    return (isUserLoggedIn()) ?
                        <Wrapper {...props} title="Customers" >

                            <Customers {...props} />

                        </Wrapper>

                        :
                        <Login {...props} />

                }} />
                <Route path="/sales/" name="sales" render={(props = this.props) => {
                    return (isUserLoggedIn()) ?
                        <Wrapper {...props} title="Sales" >

                            <Sales {...props} />

                        </Wrapper>

                        :
                        <Login {...props} />

                }} />
                <Route path="/eod/" name="eod" render={(props = this.props) => {
                    return (isUserLoggedIn()) ?
                        <Wrapper {...props} title="Eod" >

                            <Eod {...props} />

                        </Wrapper>

                        :
                        <Login {...props} />

                }} />
                <Route path="/vehicle/" name="vehicle" render={(props = this.props) => {
                    return (isUserLoggedIn()) ?
                        <Wrapper {...props} title="Vehicle" >

                            <Vehicle {...props} />

                        </Wrapper>

                        :
                        <Login {...props} />

                }} />

                <Route path="/quotation/" name="quotation" render={(props = this.props) => {
                    return (isUserLoggedIn()) ?
                        <Wrapper {...props} title="Quotation" >

                            <Quotation {...props} />

                        </Wrapper>

                        :
                        <Login {...props} />

                }} />

                <Route path="/complaint/" name="complaint" render={(props = this.props) => {
                    return (isUserLoggedIn()) ?
                        <Wrapper {...props} title="Complaint" >

                            <Complaint {...props} />

                        </Wrapper>

                        :
                        <Login {...props} />

                }} />
                {/* <Route path="/sales/" name="sales" render={(props = this.props) => {
                    if (isUserLoggedIn()) {
                        return <Sales {...props} />
                    }
                    return <Login {...props} />
                }} /> */}
                <Route path="/privacy-policy" name="privacy" render={(props = this.props) => {
                    // if (isUserLoggedIn())
                    {
                        return <Privacy {...props} />
                    }
                    return <Login {...props} />
                }} />

                <Route path="/" name="home" render={(props = this.props) => {

                    return isUserLoggedIn() ?

                        <Wrapper {...props} title="Dashboard"  >

                            <Dashboard {...props} />

                        </Wrapper>

                        :
                        <Login {...props} />
                }} />

                {/* <Route path="/police/add/:id" name="police-add" render={(props = this.props) => {
                    if (isUserLoggedIn()) {
                        return <PoliceAdd {...props} />
                    }
                    return <Login {...props} />
                }} />

                <Route path="/police/add/" name="police-add" render={(props = this.props) => {
                    if (isUserLoggedIn()) {
                        return <PoliceAdd {...props} />
                    }
                    return <Login {...props} />
                }} />

              
                <Route path="/hospital/add/:id" name="hospital-add" render={(props = this.props) => {
                    if (isUserLoggedIn()) {
                        return <HospitalAdd {...props} />
                    }
                    return <Login {...props} />
                }} />

                <Route path="/admin/hospital/add/" name="hospital-add" render={(props = this.props) => {
                    if (isUserLoggedIn()) {
                        return <HospitalAdd {...props} />
                    }
                    return <Login {...props} />
                }} />
                <Route path="/admin/hospital/" name="hospital" render={(props = this.props) => {
                    if (isUserLoggedIn()) {
                        return <Hospital {...props} />
                    }
                    return <Login {...props} />
                }} />
                <Route path="/admin/fire/add/:id" name="fire-add" render={(props = this.props) => {
                    if (isUserLoggedIn()) {
                        return <FireAdd {...props} />
                    }
                    return <Login {...props} />
                }} />
                <Route path="/admin/fire/add" name="fire-add" render={(props = this.props) => {
                    if (isUserLoggedIn()) {
                        return <FireAdd {...props} />
                    }
                    return <Login {...props} />
                }} />
                <Route path="/admin/fire" name="fire" render={(props = this.props) => {
                    if (isUserLoggedIn()) {
                        return <Fire {...props} />
                    }
                    return <Login {...props} />
                }} />

                <Route path="/admin/doctor/add/:id" name="doctor-add" render={(props = this.props) => {
                    if (isUserLoggedIn()) {
                        return <DoctorAdd {...props} />
                    }
                    return <Login {...props} />
                }} />

                <Route path="/admin/doctor/add" name="doctor-add" render={(props = this.props) => {
                    if (isUserLoggedIn()) {
                        return <DoctorAdd {...props} />
                    }
                    return <Login {...props} />
                }} />
                <Route path="/admin/doctor" name="doctor" render={(props = this.props) => {
                    if (isUserLoggedIn()) {
                        return <Doctor {...props} />
                    }
                    return <Login {...props} />
                }} />
                <Route path="/admin/pharmacy/add/:id" name="pharmacy-add" render={(props = this.props) => {
                    if (isUserLoggedIn()) {
                        return <PharmacyAdd {...props} />
                    }
                    return <Login {...props} />
                }} />
                <Route path="/admin/pharmacy/add" name="pharmacy-add" render={(props = this.props) => {
                    if (isUserLoggedIn()) {
                        return <PharmacyAdd {...props} />
                    }
                    return <Login {...props} />
                }} />
                <Route path="/admin/pharmacy" name="pharmacy" render={(props = this.props) => {
                    if (isUserLoggedIn()) {
                        return <Pharmacy {...props} />
                    }
                    return <Login {...props} />
                }} />
                <Route path="/admin/welfare/add/:id" name="welfare-add" render={(props = this.props) => {
                    if (isUserLoggedIn()) {
                        return <WelfareAdd {...props} />
                    }
                    return <Login {...props} />
                }} />
                <Route path="/admin/welfare/add" name="welfare-add" render={(props = this.props) => {
                    if (isUserLoggedIn()) {
                        return <WelfareAdd {...props} />
                    }
                    return <Login {...props} />
                }} />
                <Route path="/admin/welfare" name="welfare" render={(props = this.props) => {
                    if (isUserLoggedIn()) {
                        return <Welfare {...props} />
                    }
                    return <Login {...props} />
                }} />

                <Route path="/admin/guardians" name="guardians" render={(props = this.props) => {
                    if (isUserLoggedIn()) {
                        return <Guardians {...props} />
                    }
                    return <Login {...props} />
                }} /> */}


            </Switch>
        </Router>
    )
}
export default routes
