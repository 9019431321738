import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { getAppUser } from '../../../services/appUser'
import { getVehicleDetailService } from '../../../services/vehicle'
import ImageGallery from 'react-grid-gallery';

import { getUserInfo, getUserId } from './../../../helpers/authentication'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import loaderImage from "../../../assets/loader1.svg";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import { FormatDateSearchFilter, FormatDateSearchFilterString, FormatDate } from '../../../helpers/authentication'
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    grid: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#fff',
        cursor: 'pointer',
        borderRadius: 10
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
    },
    gridContainer: {
        padding: 15,
        backgroundColor: '#fff',
        margin: 10,
        borderRadius: 10
    }, gridHeading: {
        padding: '5px 15px',
        backgroundColor: '#fff',
        margin: 5,
        borderRadius: 10
    },
    gridContainerBottom: {

        flexDirection: 'column',
        display: 'flex',

        backgroundColor: '#fff'


    },
    divTextContainerBottom: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 35
    },
    textPerformance: {
        color: '#009330', fontWeight: 'bold', fontSize: 25, textAlign: 'flex-start', paddingBottom: 20, paddingLeft: 10, textAlign: 'flex-start'
    },
    textMonth: {
        color: '#009330', fontWeight: 'bold', fontSize: 25, paddingLeft: 15, textAlign: 'flex-end'
    },
    button: {
        width: '100%',
        height: 40
    },
    buttonExport: {
        backgroundColor: '#004500'
    },


}));

export default function VehicleDetail(props) {
    const classes = useStyles();
    const [userDetail, setUserDetail] = useState({});
    const [vehicleDetail, setVehicleDetail] = useState([]);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);

    const [startDate, setStartDate] = useState(FormatDateSearchFilter(new Date(new Date().getFullYear(), new Date().getMonth(), 1)))

    const [endDate, setEndDate] = useState(FormatDateSearchFilter(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)))

    useEffect(() => {
        getData(true)
    }, [])

    const getData = async (refresh = false,) => {
        const _id = props?.location?.state?.id || false

        if (_id) {
            setLoading(true)
            let result = await getVehicleDetailService({ _id });
            setLoading(false)
            setVehicleDetail(result.data.result)

            let imagesLocal = []

            if (result.data.result.morningReadingImage) {
                imagesLocal.push({
                    src: result.data.result.morningReadingImage,
                    thumbnail: result.data.result.morningReadingImage,
                    caption: "Morning Reading",
                    thumbnailWidth: 320,
                    thumbnailHeight: 174,

                })
            }

            if (result.data.result.eveningReadingImage) {
                imagesLocal.push({
                    src: result.data.result.eveningReadingImage,
                    thumbnail: result.data.result.eveningReadingImage,
                    caption: "Evening Reading Image",
                    thumbnailWidth: 320,
                    thumbnailHeight: 174,

                })
            }

            setImages(imagesLocal)


        }


    }

    return (


        <Grid container spacing={3}>




            <Grid container spacing={3} className={classes.gridContainer}>

                <Grid container className={classes.gridHeading}><h5>Vehicle Details</h5></Grid>
                {Object.keys(vehicleDetail).length > 0 ?
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>

                            <List className={classes.list} component="nav" aria-label="main mailbox folders">
                                <ListItem>
                                    <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>Vehicle Type</Typography>} />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText>
                                        {String(vehicleDetail?.vehicleType || 'n/a').replace("_", " ").toUpperCase()}

                                    </ListItemText>
                                </ListItem>
                            </List>


                        </Grid>
                        <Grid item xs={12} md={6}>

                            <List className={classes.list} component="nav" aria-label="main mailbox folders">
                                <ListItem>
                                    <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>Vehicle Number</Typography>} />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText>
                                        {String(vehicleDetail?.vehicleNo || 'n/a').replace("_", " ").toUpperCase()}
                                    </ListItemText>
                                </ListItem>
                            </List>


                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ListItem>
                                <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}
                                >Morning Reading</Typography>} />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText> {vehicleDetail.morningReading}</ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ListItem>
                                <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>Evening Reading</Typography>} />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText> {vehicleDetail.eveningReading}</ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ListItem>
                                <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>KM Driven</Typography>} />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText> {vehicleDetail.kmDriven}</ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ListItem>

                                <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>Date</Typography>} />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText>{FormatDate(vehicleDetail.date)}</ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>
                    : (

                        <h6>
                            No Record Found!
                        </h6>

                    )}
            </Grid>


            <Grid container spacing={3} className={classes.gridContainer}>

                <Grid container className={classes.gridHeading}><h5>Attached Images</h5></Grid>

                <Grid container spacing={3}>

                    <Grid item xs={12} md={12}>

                        <ImageGallery images={images} margin={20} enableImageSelection={false} />


                    </Grid>
                </Grid>
            </Grid>



        </Grid >
    );
}
