import moment from 'moment';

export const saveUserInfo = (user) => {
    sessionStorage.setItem('user', JSON.stringify(user.result))
    sessionStorage.setItem('token', user.token)
}

export const getUserId = () => {
    let user = JSON.parse(sessionStorage.getItem('user'))
    return user?.ID || false
}
export const getToken = () => {
    let token = sessionStorage.getItem('token')
    return token || ""
}

export const getUserInfo = () => {
    let user = JSON.parse(sessionStorage.getItem('user'))
    return user
}

export const isUserLoggedIn = () => {
    if (sessionStorage.getItem('user')) {
        return true
    }
    return false
}

export const logOutUser = () => {
    sessionStorage.removeItem('user')
}

export const FormatDateSearchFilterAddOneDay = (date) => {
    if (date) {
        var localTime = moment.utc(date).toDate();
        return moment(localTime).add(1, 'days')
    }
    return 'n/a'
}
export const FormatDateSearchFilter = (date) => {
    if (date) {
        var localTime = moment.utc(date).toDate();
        return moment(localTime).format()
    }
    return 'n/a'
}

export const FormatDateSearchFilterString = (date) => {
    if (date) {
        var localTime = moment.utc(date).toDate();
        return moment(localTime).format("YYYY-MM-DD")
    }
    return 'n/a'
}

export const FormatDate = (date) => {
    if (date) {
        var localTime = moment.utc(date).toDate();
        return moment(localTime).format('DD-MM-YYYY');
    }
    return 'n/a'
}


